export default {
  global: {
    userCenter: 'Account Center',
    userSetting: 'Account Setings',
    versionManage: 'Version Management',
    download: 'Download',
    logout: 'Logout',
  },
  login: {
    headerText: ' Ant Design is the most influential web design specification in Xihu district ',
    tabAccount: 'Credentials',
    tabPhone: 'Mobile number',
    btn: 'Login',
    getcode: 'Get code',
    remember: 'Remember me',
    forgot: 'Forgot your password?',
    usernameRule: 'Please input your username!',
    passwordRule: 'Please input your password!',
    phoneRule: 'Please input phone!',
    codeRule: 'Please input code!',
    usernamePlaceholder: 'Account: admin',
    passwordPlaceholder: 'password: admin or 123456',
    phoneNumPlaceholder: 'Mobile Number',
    phoneCodePlaceholder: 'Verification code',
    loginFail: 'Login Fail',
  },
}