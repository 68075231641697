<template>
  <div class="header-box">
    <div class="trigger" @click="toggleCollapsed()">
      <menu-unfold-outlined v-if="collapsed" />
      <menu-fold-outlined v-else />
    </div>
    <div class="refresh" @click="reload()">
      <ReloadOutlined />
    </div>
    <div class="topmenu">
      <!-- <a-menu v-model:selectedKeys="selectedKeys" mode="horizontal" :style="{ lineHeight: '64px' }">
        <a-menu-item v-for="(item) in menuList" :key="item.code" @click="getMenuList(item)">
          <icon-font :type="item.icon" v-if="item.icon" />
          <span>{{ item.title }}</span>
        </a-menu-item>
      </a-menu> -->
    </div>
    <div class="right-btns">
      <a-dropdown placement="bottomRight">
        <div class="user-box">
          <a-avatar>
            <template #icon>
              <UserOutlined />
            </template>
          </a-avatar>
          <span class="username">{{ user_name }}</span>
        </div>
        <template #overlay>
          <a-menu>
            <!-- <a-menu-item @click="goUserMember()">
              <UserOutlined class="menu-icon" />{{ $t('global.userCenter') }}
            </a-menu-item>
            <a-menu-item @click="goUserSetting()">
              <SettingOutlined class="menu-icon" />{{ $t('global.userSetting') }}
            </a-menu-item>
            <a-menu-divider /> -->
            <a-menu-item @click="goVersionManage()" v-if="isDevUser.includes(phone)">
              <ToolOutlined class="menu-icon" />{{ $t('global.versionManage') }}
            </a-menu-item>
            <a-menu-item @click="download()">
              <CloudDownloadOutlined class="menu-icon" />{{ $t('global.download') }}
            </a-menu-item>
            <a-menu-item @click="logout()">
              <LogoutOutlined class="menu-icon" />{{ $t('global.logout') }}
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
      <!-- <select-lang></select-lang> -->
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  ReloadOutlined,
  UserOutlined,
  // SettingOutlined,
  ToolOutlined,
  CloudDownloadOutlined,
  LogoutOutlined,
} from '@ant-design/icons-vue';
// import SelectLang from '@cps/select-lang.vue';
import { isDevUser } from '@/config/default.config';
import { useUserStore } from '@/store/user';
import useMenuCollapse from '@/hooks/useMenuCollapse';
import { removeToken, removeRefreshToken } from '@/utils/auth';

const { $message, $modal } = getCurrentInstance().proxy;

const user = useUserStore();
const router = useRouter();
const route = useRoute();

const phone = computed(() => user.phone);
const user_name = computed(() => user.user_name);

const { collapsed, toggleCollapsed } = useMenuCollapse();

// function goUserMember() {
//   router.replace({ name: 'userMember' });
// }
// function goUserSetting() {
//   router.replace({ name: 'userSetting' });
// }
function download() {
  router.replace({ name: 'download' });
}
function goVersionManage() {
  router.replace({ name: 'versionManage' });
}
function logout() {
  $modal.confirm({
    title: '提示',
    content: '确定退出登录',
    okText: '确认',
    cancelText: '取消',
    onOk() {
      router.replace({
        name: 'login',
        query: {
          redirect: route.path,
        }
      });
      localStorage.removeItem('perms');
      removeToken();
      removeRefreshToken();
    },
    onCancel() { }
  });
}
function reload() {
  router.replace({
    path: '/redirect' + route.path,
  });
}
</script>

<style lang="less" scoped>
.header-box {
  display: flex;
  width: 100%;
}

.trigger,
.refresh {
  display: flex;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
  transition: all 0.36s;
  font-size: 18px;

  &:hover,
  &:active {
    background-color: #eee;
  }
}

.topmenu {
  flex: 1;
  margin-left: 20px;
}

.right-btns {
  display: flex;
  align-items: center;
  padding-right: 20px;

  .user-box {
    display: flex;
    align-items: center;
    cursor: pointer;

    .username {
      margin-left: 5px;
      font-size: 16px;
      color: #666;
    }
  }
}

::v-deep(.menu-icon) {
  margin-right: 10px;
}
</style>