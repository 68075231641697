import { isUndefined } from 'lodash-es';
import SparkMD5 from 'spark-md5';
import { md5 } from 'js-md5';
import * as XLSX from 'xlsx';

export function sleep(time: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
}

export function isNumber(data) {
  return Object.prototype.toString.call(data) === '[object Number]';
}
export function isString(data) {
  return Object.prototype.toString.call(data) === '[object String]';
}
export function isArray(data) {
  return Object.prototype.toString.call(data) === '[object Array]';
}
export function isObject(data) {
  return Object.prototype.toString.call(data) === '[object Object]';
}

export function isDeFined(data) {
  return data !== undefined && data !== null;
}

export function isEmptyObject(data) {
  return JSON.stringify(data) === '{}';
}

// 字符串是否驼峰格式
export function isHump(str) {
  return /^on(\S)/.test(str);
}

/**
 * 邮箱
 * @param {*} s
 */
export function isEmail(s) {
  return /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(
    s,
  );
}

/**
 * URL地址
 * @param {*}
 */
export function isURL(s) {
  return /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/.test(s);
}

// 验证是否为手机号码
export function isValidPhone(str) {
  var myreg = /^[1][3-9][0-9]{9}$/;
  if (!myreg.test(str)) {
    return false;
  } else {
    return true;
  }
}

// 获取浏览器指纹
export function getBrowserFingerprint() {
  // 获取用户代理字符串
  const userAgent = navigator.userAgent;

  // 获取屏幕分辨率
  const screenResolution = `${screen.width}x${screen.height}`;

  // 获取浏览器语言
  const language = navigator.language;

  // 获取时区
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // 获取浏览器插件信息
  let plugins = '';
  if (navigator.plugins && navigator.plugins.length > 0) {
    for (let i = 0; i < navigator.plugins.length; i++) {
      plugins += navigator.plugins[i].name;
    }
  }

  // 获取Canvas指纹
  let canvasPrint = '';
  const canvas = document.createElement('canvas');
  if (canvas.getContext && canvas.getContext('2d')) {
    const ctx = canvas.getContext('2d');
    ctx.textBaseline = 'top';
    ctx.font = "14px 'Arial'";
    ctx.textBaseline = 'alphabetic';
    ctx.fillStyle = '#f60';
    ctx.fillRect(125, 1, 62, 20);
    ctx.fillStyle = '#069';
    ctx.fillText('BrowserFingerprint', 2, 15);
    ctx.fillStyle = 'rgba(102, 204, 0, 0.7)';
    ctx.fillText('BrowserFingerprint', 4, 17);
    canvasPrint = canvas.toDataURL();
  }

  // 组合所有信息生成指纹
  return md5([userAgent, screenResolution, language, timezone, plugins, canvasPrint].join('_')).toUpperCase();
}

/**
 * 获取url参数
 * @param name 参数名
 */
export function getQueryString(name) {
  let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  let r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(decodeURI(r[2]));
  return null;
}

// 序列化对象
export function serialize(obj) {
  let str = [];
  for (let p in obj) {
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  }
  return str.join('&');
}

export function getValByDefault(attrs, key, defaultValue) {
  return isUndefined(attrs[key])
    ? defaultValue
    : attrs[key];
}

// 数组转对象
export function arrayToObject(datas, key, valueKey) {
  let obj = {};
  datas.forEach((item) => {
    obj[item[key]] = item[valueKey];
  });
  return obj;
}

// 深拷贝数据，并改变字段
export function deepChangeKey(arr = [], keys = {}) {
  const isNullKeys = isEmptyObject(keys);
  return arr.map((item) => {
    let data = {
      ...item,
    };
    if (!isNullKeys) {
      Object.keys(keys).forEach((key) => {
        data[key] = data[keys[key]];
      });
    }
    if (item.children && item.children.length > 0) {
      data.children = deepChangeKey(item.children);
    }
    return data;
  });
}

// 获取文件指定分片
export function readFile(file) {
  return new Promise((resolve) => {
    const fileReader = new FileReader();
    fileReader.onload = (event: ProgressEvent<FileReader>) => {
      resolve(event.target.result);
    }
    fileReader.readAsArrayBuffer(file);
  });
}

// 获取文件指定分片
export function createChunk(file, index, chunkSize) {
  return new Promise((resolve) => {
    const start = index * chunkSize;
    const end = start + chunkSize;
    const spark = new SparkMD5.ArrayBuffer();
    const fileReader = new FileReader();
    fileReader.onload = (event: ProgressEvent<FileReader>) => {
      spark.append(event.target.result);
      resolve({
        start,
        end,
        index,
        buffer: event.target.result,
        hash: spark.end(),
      });
    }
    fileReader.readAsArrayBuffer(file.slice(start, end));
  });
}

// 随机生成颜色
export function generateRandomColor(opacity?: number) {
  // 生成三个随机数作为 RGB 值
  var r = Math.floor(Math.random() * 256);
  var g = Math.floor(Math.random() * 256);
  var b = Math.floor(Math.random() * 256);
  // 组装成颜色字符串
  if (opacity >= 0) {
    return `rgba(${r},${g},${b},${opacity})`;
  } else if (opacity < 0) {
    var a = Math.floor(Math.random() * 100) / 100;
    return `rgba(${r},${g},${b},${a})`;
  } else {
    return `rgb(${r},${g},${b})`;
  }
}

export function getAddressCity(address: string = '') {
  let city = '';
  if (address.indexOf('义乌市') >= 0 || address.indexOf('义乌') == 0) {
    city = '金华市';
  }
  const matchArr = address.match(/.+?(省|市|自治区|自治州)/g);
  if (matchArr && matchArr.length > 0) {
    let obj = matchArr.find(item => item.indexOf('市') >= 0 || item.indexOf('自治州') >= 0);
    if (obj) {
      city = obj;
    }
  }
  return city;
}

export function treeToFlat(tree, key = 'id') {
  const result = [];

  for (const item of tree) {
    // 如果是叶子节点，直接将值添加到结果数组中
    result.push({
      ...item,
    });
    if (item.children) {
      // 如果是子节点，递归调用
      result.push(...treeToFlat(item.children, item[key]));
    }
  }

  return result;
}

export function getParentId(list, id) {
  for (let i in list) {
    if (list[i].id == id) {
      return [list[i]];
    }
    if (list[i].children) {
      let node = getParentId(list[i].children, id);
      if (node !== undefined) {
        return node.concat(list[i]);
      }
    }
  }
}

// 过滤对象空值
export function filterEmpty(obj) {
  let newObj = {};
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
        newObj[key] = obj[key];
      }
    }
  }
  return newObj;
}

/**
 * 根据对象的路径查找值
 * @param {*} path 路径，如a.b.c
 * @param {*} obj 对象
 * @param {*} defaultValue 如果没有找到返回的默认值
 */
export function getObjectByPath(path: string, obj: object, defaultValue = undefined): any {
  const paths = path.split('.');
  let currentObj = obj;
  for (let i = 0, len = paths.length; i < len; i++) {
    const item = paths[i];
    if (isObject(currentObj) && item in currentObj) {
      currentObj = currentObj[item];
    } else {
      currentObj = defaultValue;
    }
  }
  return currentObj;
}

// 获取表单验证错误信息
export function getFormValidate(errorFields) {
  const messages = [];
  const names = [];
  errorFields.forEach(item => {
    if (item.errors.length > 0) {
      messages.push(item.errors[0]);
    }
    if (item.name.length > 0) {
      names.push(item.name[0]);
    }
  });
  return {
    names,
    messages,
  }
}

// xlsx读取数据
export function xlsxReader(file) {
  return new Promise(function (resolve, reject) {
    let reader = new FileReader();
    reader.onload = function (e) {
      const data = e.target.result;
      let wb = XLSX.read(data, {
        type: 'binary',
      });
      const result = [];
      wb.SheetNames.forEach((sheetName) => {
        result.push({
          sheetName: sheetName,
          sheet: XLSX.utils.sheet_to_json(wb.Sheets[sheetName], { header: 1 }),
        });
      });
      reader.onload = null;
      reader.onerror = null;
      reader = null;
      resolve(result);
    };
    reader.onabort = (error) => {
      console.log(error);
      reject(error);
    };
    reader.onerror = (error) => {
      console.log(error);
      reject(error);
    };
    reader.readAsBinaryString(file);
  });
}

export function download(url, responseType, events?: { onError?: Function, onProgress?: Function }) {
  const { onError, onProgress } = events || {};
  return new Promise(function (resolve, reject) {
    let xhr = new XMLHttpRequest();
    xhr.responseType = responseType;
    xhr.open('GET', url, true);
    xhr.onload = function () {
      xhr.onload = null;
      xhr.onerror = null;
      xhr.onprogress = null;
      xhr = null;
      if (this.status === 200) {
        resolve(this.response);
      } else {
        reject();
      }
    };
    xhr.onprogress = function (e) {
      onProgress && onProgress(Math.round(e.loaded / e.total * 10000) / 100);
    }
    xhr.onerror = function (e) {
      onError && onError(e);
      reject();
    };
    xhr.send();
  });
}

export function getFormatSize(size) {
  if (size / 1024 / 1024 / 1024 >= 1) {
    return Math.floor((size / 1024 / 1024 / 1024) * 100) / 100 + 'GB';
  }
  if (size / 1024 / 1024 >= 1) {
    return Math.floor((size / 1024 / 1024) * 100) / 100 + 'MB';
  }
  return Math.floor((size / 1024) * 100) / 100 + 'KB';
}

export function generateColorByStr(str) {
  if (!str) return;
  if (sessionStorage.getItem(str)) {
    return sessionStorage.getItem(str);
  }
  const hex = md5(str).toString().toUpperCase();
  const rgb = [
    parseInt(hex.substring(0, 2), 16),
    parseInt(hex.substring(2, 4), 16),
    parseInt(hex.substring(4, 6), 16)
  ];
  const color = `rgb(${rgb.join(', ')})`;
  sessionStorage.setItem(str, color);
  return color;
}

// 复制文本
export function copyText(text) {
  const input = document.createElement('input');
  input.value = text;
  document.body.appendChild(input);
  input.select();
  document.execCommand('copy');
  document.body.removeChild(input);
}

// 判断菜单权限
export function hasPerm(menu) {
  const data = toRaw(menu);
  if (!data.perm || data.perm.length == 0) return true;
  const perms = (localStorage.getItem('perms') || '').split(',');
  const flag1 = typeof data.perm === 'string' && perms.includes(data.perm);
  const flag2 = isArray(data.perm) && (data.perm as string[]).some(subitem => perms.includes(subitem));
  return flag1 || flag2;
}

// 判断是否为诚信码
export function isValidCxNumber(str) {
  const isCxBarcode = /^([0-9]{11,12})-([0-9]{1,})$/; // 诚信条码规则（格式为：{11位数字}-{数字}）
  const isCxCode = /^([0-9]{11,12})$/; // 诚信单号规则
  if (!isCxCode.test(str)) {
    return false;
  }
  // 第1位年份
  // 第2、3位月份
  // 第4、5位日期
  // 最后1位校验码
  let arr = str.split('');
  let len = arr.length;
  let checkCode = str.substring(len - 1, len); // 校验码
  let yearStr = str.substring(0, 1); // 年份
  let monthStr = str.substring(1, 3); // 月份
  let dayStr = str.substring(3, 5); // 日期
  let date = new Date();
  let nowYear = `${date.getFullYear()}`;
  let createDate = new Date(`${nowYear.substring(0, 3) + yearStr}-${monthStr - 1}-${dayStr}`);
  if (createDate.toString() == 'Invalid Date') {
    // 日期条件不满足
    return false;
  }

  let factor = [4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2, 1]; // 加权因子
  let sIndex = factor.length - len;
  let sum = 0;
  for (let i = 0; i < len - 1; i++) {
    sum += factor[sIndex + i] * arr[i];
  }
  let result = 12 - sum % 11;

  result = result >= 11 ? result - 11 : (result == 10 ? 0 : result);
  // console.log('当前校验码：', checkCode);
  // console.log('正确校验位：', result);
  return result == checkCode;
}

// 计算多边形的质心
export function calculatePolygonCentroid(points) {
  // 计算多边形的面积
  let area = 0;
  for (let i = 0; i < points.length; i++) {
    let x1 = points[i].lat;
    let y1 = points[i].lon;
    let x2 = points[(i + 1) % points.length].lat;
    let y2 = points[(i + 1) % points.length].lon;
    area += (x1 * y2 - x2 * y1);
  }
  area = Math.abs(area) / 2;

  // 计算多边形的重心
  let centroidX = 0;
  let centroidY = 0;
  for (let i = 0; i < points.length; i++) {
    let x1 = points[i].lat;
    let y1 = points[i].lon;
    let x2 = points[(i + 1) % points.length].lat;
    let y2 = points[(i + 1) % points.length].lon;
    let weight = (x1 * y2 - x2 * y1);
    centroidX += (x1 + x2) * weight;
    centroidY += (y1 + y2) * weight;
  }
  centroidX /= 6 * area;
  centroidY /= 6 * area;

  // 将重心坐标转换为笛卡尔坐标系
  let result = { lat: centroidX, lon: centroidY };
  return result;
}